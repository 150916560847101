<template>
  <div v-loading="isLoading" class="share">
    <div v-if="!canImprove" class="share__disabled" />
    <div class="share__free">
      Нераспределенных таймслотов: {{ unimprovedRest }}
    </div>
    <div class="share__container">
      <div
        v-for="(item, index) in suppliersQuotas"
        :key="index"
        class="share__supplierQuota-container"
      >
        <div class="share__supplierQuota">
          <div>Поставщик</div>
          <div class="share__supplierQuota-info">
            <div :class="['info__select', { error: item.hasSupplierError }]">
              <IqSelect
                :value="item.supplierId"
                :options="suppliersList"
                :disabled="Boolean(item.id)"
                @focus="clearValidation(index)"
                @input="changeSupplierId(index, $event)"
              />
            </div>
            <div :class="['info__quantity', { error: item.hasQuantityError }]">
              <SimpleInputNumber
                :value="item.quantity"
                @focus="clearValidation(index)"
                @input="changeSupplierQuantity(index, $event)"
              />
            </div>
            <div class="df">
              <div class="info__icon">
                <SuccessIcon
                  v-if="needAccept(index)"
                  @icon-clicked="handleSaveQuota(index)"
                />
              </div>
              <div class="info__icon">
                <CloseIcon @icon-clicked="handleRemoveQuota(index)" />
              </div>
            </div>
          </div>
        </div>
        <el-collapse-transition>
          <div v-show="item.errorText.length" class="share__error">
            {{ item.errorText }}
          </div>
        </el-collapse-transition>
      </div>
      <div class="share__addQuota" @click="handleAddSupplierQuota">
        <GreenPlusIcon />
        <span class="share__addQuota-text">Добавить квоту на поставщика</span>
      </div>

      <!-- Выдача общей квоты     -->
      <div
        :class="['share__common', { 'share-active': !commonQuota }]"
        @click="handleAddCommonQuota"
      >
        <div>
          <div class="share__common-title">Общая квота</div>
          <div class="share__common-description">
            Доступна для бронирования любому водителю
          </div>
        </div>

        <i :class="[{ rotated: commonQuota }, 'el-icon-arrow-right']" />
      </div>

      <div v-if="commonQuota">
        <div class="share__supplierQuota">
          <div>Общее</div>
          <div class="share__supplierQuota-info">
            <div
              :class="[
                'info__commonInput',
                { error: commonQuota.errorText.length },
              ]"
            >
              <SimpleInputNumber
                :value="commonQuota.quantity"
                @input="changeCommonQuantity"
                @focus="commonQuota.errorText = ''"
              />
            </div>
            <div class="df">
              <div class="info__icon">
                <SuccessIcon
                  v-if="
                    !commonQuota.isAccepted &&
                    commonQuota.quantity !== undefined
                  "
                  @icon-clicked="handleSaveQuota(-1)"
                />
              </div>
              <div class="info__icon">
                <CloseIcon @icon-clicked="handleRemoveQuota(-1)" />
              </div>
            </div>
          </div>
        </div>
        <el-collapse-transition>
          <div v-show="commonQuota.errorText.length" class="share__error">
            {{ commonQuota.errorText }}
          </div>
        </el-collapse-transition>
      </div>
      <div class="share__button">
        <iq-button
          size="small"
          :disabled="!canImprove || !canSave"
          @onClick="handleCloseForm"
        >
          Закрыть
        </iq-button>
      </div>
    </div>
  </div>
</template>

<script>
import { DIALOG_ADD_QUOTA } from '@/constants/dialogs'
import { FETCH_LIMIT_QUOTAS } from '@/views/home/store/actions'
import {
  FETCH_SUPPLIERS_FOR_EXPORTER,
  GET_IS_LOADING_SUPPLIERS,
  GET_SUPPLIERS_FOR_EXPORTER,
  SET_IS_LOADING_SUPPLIERS,
} from '@/views/exporter/store/actions'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Api from '@/api'
import CloseIcon from '@/assets/icons/CloseIconMobile'
import GreenPlusIcon from '@/assets/icons/GreenPlusIcon'
import IqButton from '@/views/ui/components/buttons/IqButton'
import IqSelect from '@/views/ui/components/select/IqSelect.vue'
import SimpleInputNumber from '@/views/ui/components/input/SimpleInputNumber.vue'
import SuccessIcon from '@/assets/icons/SuccessIconMobile'

export default {
  name: 'ShareQuotaForm',
  components: {
    IqButton,
    IqSelect,
    CloseIcon,
    GreenPlusIcon,
    SimpleInputNumber,
    SuccessIcon,
  },
  props: {
    quota: {
      type: Object,
      default: () => ({}),
    },
    canImprove: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      suppliersQuotas: [],
      unimprovedRest: 0,
      commonQuota: undefined,
      errorText: '',
    }
  },
  computed: {
    ...mapGetters({
      suppliersList: GET_SUPPLIERS_FOR_EXPORTER,
      isLoading: GET_IS_LOADING_SUPPLIERS,
    }),
    // даём закрывать форму только когда все изменения записаны в базу
    canSave() {
      let allAccepted = true

      this.suppliersQuotas.forEach(item => {
        if (!item.isAccepted) {
          allAccepted = false
        }
      })

      if (this.commonQuota && !this.commonQuota.isAccepted) {
        allAccepted = false
      }

      return allAccepted
    },
  },
  watch: {
    quota(val) {
      if (Object.keys(val).length) {
        this.initQuotaData()
      }
    },
  },
  mounted() {
    this.initQuotaData()
  },
  methods: {
    ...mapActions({
      fetchSuppliers: FETCH_SUPPLIERS_FOR_EXPORTER,
      refreshQuotas: FETCH_LIMIT_QUOTAS,
    }),
    ...mapMutations({ setLoading: SET_IS_LOADING_SUPPLIERS }),

    initQuotaData() {
      this.fetchSuppliers({
        exporterId: this.quota.exporters_ids[0],
        callback: this.initSuppliersQuotas,
      })
      // разрешаем редактирование количества машин в блоке редактирования,
      // пока здесь не вносили никаких изменений
      this.$emit('improved', false)
    },

    // заполняем массив существующих квот поставщиков и считаем нераспределенный остаток
    initSuppliersQuotas() {
      this.suppliersQuotas = []
      this.commonQuota = undefined

      let usedQuotaLimit = 0

      this.quota.child_quotas.forEach(item => {
        if (item.suppliers_ids.length) {
          // квоты на поставщиков
          this.suppliersQuotas.push({
            supplierId: item.suppliers_ids[0],
            quantity: item.trucks_allowed_count,
            id: item.id,
            isAccepted: true,
            hasSupplierError: false,
            hasQuantityError: false,
            errorText: '',
          })
        } else {
          // общая квота
          this.commonQuota = {
            quantity: item.trucks_allowed_count,
            id: item.id,
            isAccepted: true,
            errorText: '',
          }
        }
        usedQuotaLimit += item.trucks_allowed_count
      })
      this.unimprovedRest = this.quota.trucks_allowed_count - usedQuotaLimit
    },

    // пересчитываем нераспределенную квоту после изменений
    recalculateUnimprovedRest() {
      let usedQuotaLimit = this.suppliersQuotas.reduce((sum, item) => {
        // учитываем только подтвержденные квоты
        if (item.id) {
          return sum + item.quantity
        }

        return sum
      }, 0)

      if (this.commonQuota && this.commonQuota.isAccepted) {
        usedQuotaLimit += this.commonQuota.quantity
      }
      this.unimprovedRest = this.quota.trucks_allowed_count - usedQuotaLimit
    },
    // добавление пустой квоты поставщика
    handleAddSupplierQuota() {
      this.suppliersQuotas.push({
        supplierId: '',
        quantity: undefined,
        id: undefined,
        isAccepted: false,
        hasSupplierError: false,
        hasQuantityError: false,
        errorText: '',
      })
      // запрещаем редактирование количества машин в блоке редактирования
      this.$emit('improved', true)
    },
    // добавление общей квоты
    handleAddCommonQuota() {
      if (!this.commonQuota) {
        this.commonQuota = {
          quantity: undefined,
          id: undefined,
          isAccepted: false,
          errorText: '',
        }
        // запрещаем редактирование количества машин в блоке редактирования
        this.$emit('improved', true)
      }
    },

    // флаг необходимости отображения иконки подтверждения у квоты поставщика
    needAccept(index) {
      return (
        this.suppliersQuotas[index].supplierId &&
        this.suppliersQuotas[index].quantity !== undefined &&
        !this.suppliersQuotas[index].isAccepted
      )
    },

    validateSupplierQuota(index) {
      let canRequest = true
      let quota = this.suppliersQuotas[index]

      // проверяем уникальность поставщика (нельзя две квоты на одного)
      if (
        this.suppliersQuotas.filter(
          item => item.supplierId === quota.supplierId,
        ).length > 1
      ) {
        canRequest = false
        this.suppliersQuotas[index].errorText =
          'Квота для этого поставщика уже создана!'
        this.suppliersQuotas[index].hasSupplierError = true
      } else if (this.validateQuotaLimit(quota, index)) {
        this.suppliersQuotas[index].errorText =
          'Превышен объем доступных таймслотов!'
        this.suppliersQuotas[index].hasQuantityError = true
        canRequest = false
      } else if (this.suppliersQuotas[index].quantity === 0) {
        this.suppliersQuotas[index].errorText =
          'Необходимо указать объем квоты!'
        this.suppliersQuotas[index].hasQuantityError = true
        canRequest = false
      }

      return canRequest
    },

    validateCommonQuota(index) {
      if (this.validateQuotaLimit(this.commonQuota, index)) {
        this.commonQuota.errorText = 'Превышен объем доступных таймслотов!'

        return false
      }
      if (this.commonQuota.quantity === 0) {
        this.commonQuota.errorText = 'Необходимо указать объем квоты!'

        return false
      }

      return true
    },

    validateQuotaLimit(quota, index) {
      // определяем общую распределенную квоту с учетом внесенного изменения
      let totalCount = this.suppliersQuotas.reduce((sum, item, i) => {
        if (item.id && i !== index) {
          return sum + item.quantity
        }

        return sum
      }, 0)

      if (this.commonQuota && this.commonQuota.isAccepted) {
        totalCount += this.commonQuota.quantity
      }
      totalCount += quota.quantity

      return totalCount > this.quota.trucks_allowed_count
    },

    clearValidation(index) {
      this.suppliersQuotas[index].errorText = ''
      this.suppliersQuotas[index].hasSupplierError = false
      this.suppliersQuotas[index].hasQuantityError = false
    },

    async handleSaveQuota(index) {
      let validationMethod =
        index === -1 ? this.validateCommonQuota : this.validateSupplierQuota

      if (validationMethod(index)) {
        let requestData

        if (index === -1) {
          // сохраняем общую квоту
          requestData = {
            id: this.commonQuota.id,
            parent_id: this.quota.id,
            quota: this.commonQuota.quantity,
          }
        } else {
          // сохраняем квоту поставщика
          requestData = {
            id: this.suppliersQuotas[index].id,
            parent_id: this.quota.id,
            supplier_id: this.suppliersQuotas[index].supplierId,
            quota: this.suppliersQuotas[index].quantity,
          }
        }

        try {
          this.setLoading(true)
          let { data } = await Api.quota.saveSupplierQuota(requestData)

          if (index === -1) {
            this.commonQuota.id = data.id
            this.commonQuota.isAccepted = true
          } else {
            this.suppliersQuotas[index].id = data.id
            this.suppliersQuotas[index].isAccepted = true
          }

          this.recalculateUnimprovedRest()
        } catch (e) {
          console.log(e.response.data)
        } finally {
          this.setLoading(false)
        }
      }
    },

    // удаление квоты или незаполненного элемента
    handleRemoveQuota(index) {
      this.$confirm(
        'Вы действительно хотите удалить квоту?',
        'Подтверждение операции',
        {
          confirmButtonText: 'Да',
          cancelButtonText: 'Отмена',
          type: 'warning',
        },
      )
        .then(async () => {
          let deletingQuota =
            index === -1 ? this.commonQuota : this.suppliersQuotas[index]

          // запрос на удаление отправляем только по подтвержденной квоте
          if (deletingQuota.id) {
            try {
              this.setLoading(true)
              await Api.quota.deleteSupplierQuota(deletingQuota.id)
            } catch (e) {
              console.log(e.response.data)
            } finally {
              this.setLoading(false)
            }
          }

          index === -1
            ? (this.commonQuota = undefined)
            : this.suppliersQuotas.splice(index, 1)

          this.recalculateUnimprovedRest()
          // запрещаем редактирование количества машин в блоке редактирования
          this.$emit('improved', true)
        })
        .catch(() => {})
    },

    changeSupplierQuantity(index, value) {
      this.suppliersQuotas[index].isAccepted = false
      this.suppliersQuotas[index].quantity = Number(value)
      // запрещаем редактирование количества машин в блоке редактирования
      this.$emit('improved', true)
    },

    changeSupplierId(index, value) {
      this.suppliersQuotas[index].supplierId = value
      // запрещаем редактирование количества машин в блоке редактирования
      this.$emit('improved', true)
    },

    changeCommonQuantity(value) {
      this.commonQuota.quantity = Number(value)
      this.commonQuota.isAccepted = false
      // запрещаем редактирование количества машин в блоке редактирования
      this.$emit('improved', true)
    },

    handleCloseForm() {
      // отменяем запрет на редактирование блока основной квоты
      this.$emit('improved', false)
      this.setDialog({ name: DIALOG_ADD_QUOTA })
      this.refreshQuotas()
    },
  },
}
</script>

<style lang="sass" scoped>
.share
  position: relative
  &__free
    height: 44px
    padding-left: 16px
    line-height: 44px
    font-size: 16px
    background: $color-blue-light
    color: $color-white

  &__container
    display: flex
    flex-direction: column
    gap: 10px
    padding: 20px 16px 16px

  &__supplierQuota-container
    @media(max-width: 1199px)
      margin-bottom: 16px

  &__supplierQuota
    display: flex
    justify-content: space-between
    align-items: center
    @media(max-width: 1199px)
      flex-direction: column
      align-items: flex-start

    &-info
      display: flex
      justify-content: flex-end
      align-items: center
      @media(max-width: 1199px)
        flex-wrap: wrap
        justify-content: space-between
        width: 100%
      .info__select
        width: 252px
        @media(max-width: 1199px)
          width: 100%
          margin-bottom: 10px
      .info__quantity
        width: 100px
        margin-left: 10px
        @media(max-width: 1199px)
          margin-left: 0
      .info__icon
        width: 28px
        margin-left: 16px
        cursor: pointer
      .info__commonInput
        width: 362px
        @media(max-width: 1199px)
          width: 60%
      .error
        border: 1px solid $color-red-primary
        border-radius: 4px

  &__addQuota
    display: flex
    align-items: center
    width: fit-content
    color: $color-green
    cursor: pointer
    &-text
      margin-left: 4px

  &__common
    display: flex
    justify-content: space-between
    align-items: center
    padding-right: 10px
    &.share-active
      cursor: pointer
    &-title
      font-size: 16px
    &-description
      font-size: 12px
    .rotated
      rotate: 90deg

  &__button
    display: flex
    justify-content: flex-end
    margin-top: 18px

  &__error
    margin-top: 2px
    margin-left: 190px
    font-size: 12px
    color: $color-red-primary
    @media(max-width: 1199px)
      margin-left: 0

  &__disabled
    position: absolute
    width: 100%
    height: 100%
    z-index: 50
</style>
